import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { io } from 'socket.io-client';
import isLoggedIn from '../../helpers/checkAuth';

@withCookies
@connect(store => ({
  auth: store.auth,
}))
export default class WsProvider extends React.Component {
  static propTypes = {
    auth: PropTypes.object, // eslint-disable-line
    children: PropTypes.node.isRequired,
    query: PropTypes.shape({}),
  };

  static defaultProps = {
    query: {},
  };

  static contextTypes = {
    cookiePrefix: PropTypes.string,
  };

  static childContextTypes = {
    ws: PropTypes.shape({}),
    initWs: PropTypes.func,
    closeWs: PropTypes.func,
  };

  getChildContext() {
    return {
      ws: this.ws,
      closeWs: this.closeWs,
      initWs: this.initWs,
    };
  }

  componentDidMount() {
    const { auth } = this.props;
    if (isLoggedIn(auth)) {
      this.initWs();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      (isLoggedIn(this.props.auth) && !isLoggedIn(nextProps.auth)) ||
      (_.get(this, 'props.auth.user._id') &&
        _.get(nextProps, 'auth.user._id') &&
        _.get(this, 'props.auth.user._id') !==
          _.get(nextProps, 'auth.user._id'))
    ) {
      this.closeWs();
    }

    if (
      (!isLoggedIn(this.props.auth) && isLoggedIn(nextProps.auth)) ||
      (_.get(this, 'props.auth.user._id') &&
        _.get(nextProps, 'auth.user._id') &&
        _.get(this, 'props.auth.user._id') !==
          _.get(nextProps, 'auth.user._id'))
    ) {
      this.initWs();
    }
  }

  componentWillUnmount() {
    this.closeWs();
  }

  initWs = () => {
    const { query } = this.props;

    if (this.ws) {
      return this.ws;
    }

    this.ws = io(window.App.wsUrl, {
      transports: ['websocket'],
      path: window.App.wsPath,
      query: { ...query },
      withCredentials: true,
    });

    return this.ws;
  };

  closeWs = () => {
    if (this.ws) {
      this.ws.close();
    }
  };

  render() {
    return this.props.children;
  }
}
